import React, {useState} from 'react';
import {
  Button
} from '@material-ui/core'
import HistorySubs from "./HistorySubs";
import ComingUp from "./ComingUp";

function HistoryComponent(props) {
  const [view, setView] = useState('all')

  return (
    <div>
      <div style={{width: '90%', margin: 'auto'}}>
        <Button variant={"contained"} color={view === 'all' ? "primary" : "inherit"}
                style={{margin: 5}}
                onClick={() => {
                  setView('all')
                }}
        >
          all history
        </Button>
        <Button variant={"contained"} color={view === 'coming' ? "primary" : "inherit"}
                style={{margin: 5}}
                onClick={() => {
                  setView('coming')
                }}
        >
          Coming Up
        </Button>
      </div>
      {view === 'all' &&
      <HistorySubs/>
      }
      {view === 'coming' &&
      <ComingUp/>
      }
    </div>
  );
}

export default HistoryComponent;
