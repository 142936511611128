import React, {useState} from 'react';
import {isMobileOnly} from "react-device-detect";
import MetamorItems from "./MetamorItems";
import {BorderButton} from "../common/BorderButton";
import styled, {css} from "styled-components";
import {useGlobal} from "reactn";

const ContentButton = styled.button`
    display: inline-block;
    position: relative;
    border: solid 1px #000;
    font-size: 28pt;
    outline: none !important;
    width: 8%;
    margin: 0 0.5%;
    cursor: pointer;
    padding: 0;
    ${props => props.isMobile && css`
        width: 12%;
    `}
`;
const ImgContent = styled.div`
    position: relative;
    padding-top: 100%;
`;
function HoverMetamors(props) {
  const {metamor, index} = props
  const [metamorHoverAnchor, setMetamorHoverAnchor] = useState('')
  const [metamors, setMetamors] = useGlobal('metamor')
  const toggleMetamor = (index) => {
    metamors[index] = {...metamors[index], checked: !metamors[index].checked}
    setMetamors([...metamors])
  }
  return (
      <ContentButton
        isMobile={isMobileOnly}
        key={metamor.uuid}
        onClick={() => toggleMetamor(index)}
        onMouseOver={(event) => {
          setMetamorHoverAnchor(event.currentTarget)
        }}
        onMouseLeave={(event) => {
          setMetamorHoverAnchor(null)
        }}
      >
        {Boolean(metamorHoverAnchor) &&
        <MetamorItems metamorHoverAnchor={metamorHoverAnchor}
                      setMetamorHoverAnchor={setMetamorHoverAnchor}
                      metamor={metamor}/>}
        <ImgContent>
          <img className="img"
               src={(metamor.profile && metamor.profile.avatar) || "img/hi.jpg"}
               alt=""/>
        </ImgContent>
        <BorderButton isMobile={isMobileOnly} checked={metamor.checked}/>
      </ContentButton>
  );
}

export default HoverMetamors;
