import React, {useEffect, useRef, useState} from 'react';
import {useGlobal} from 'reactn';
import {isMobileOnly} from 'react-device-detect';
import moment from 'moment';
import {Typography} from '@material-ui/core';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import './calendarCore.css';
// import './calendarList.css'
import './calendarGrid.css';
import {makeStyles} from '@material-ui/core/styles';
import _ from 'lodash';
import {eventRender} from './eventRender';
import {columnHeard} from './columnHead';
import {getCalendarEvents, getEventsInfo} from './Actions';
import store from 'store';

const useStyles = makeStyles(theme => ({
  text: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  calendar: {
    marginTop: 10,
  },
  events: {
    textAlign: 'center',
    height: '100%',
    minHeight: '1em',
    position: 'relative',
    textAlight: 'center',
  },
  checkbox: {
    top: '50%',
    position: 'absolute',
    left: '50%',
    margin: 0,
    padding: 0,
    transform: 'translate(-50%, -50%)',
    marginRight: '-50%',
    color: 'red',
  },
  headerHtml: {
    padding: 3,
    fontSize: '1em',
    [theme.breakpoints.down('md')]: {
      fontSize: '7px',
    },
  },
  headerHtmlDay: {
    padding: 5,
    fontSize: '1rem',
  },
  headerHtmlSmallDay: {
    fontSize: '0.8rem',
    padding: 2,
  },
  headerHr: {
    height: 1,
    width: '100%',
    backgroundColor: '#ccc',
    position: 'absolute',
    left: -1,
    [theme.breakpoints.down('md')]: {
      width: '110%',
    },
  },
  headerHtmlSmall: {
    fontSize: '1rem !important',
  },
}));

function CalendarComponent(props) {
  const classes = useStyles();
  const [date] = useGlobal('date');
  const [user] = useGlobal('user');
  const [myPaid] = useGlobal('myPaid');
  const [googleCalendars] = useGlobal('googleCalendars');
  const [events, setEvents] = useState([]);
  const [open, setOpen] = useState(false);
  const locale = store.get('locale') || 'ja';
  const ref = useRef();

  useEffect(() => {
    if (ref && ref.current) {
      let calendarApi = ref.current.getApi();
      calendarApi.gotoDate(date);
    }
  }, [date]);

  useEffect(() => {
    onFresh();
  }, [googleCalendars, date, myPaid]);

  const onFresh = async () => {
    let range = isMobileOnly ? 7 : 14;
    let startTime = moment(date).startOf('week').utc().format();
    let endTime = moment(startTime).add(range, 'day').utc().format();

    const ggSchedules = await getEventsInfo(
      moment(startTime).subtract('day').utc().format(),
      moment(endTime).endOf('day').utc().format(),
    );

    const geSchedules = await getCalendarEvents(
      moment(startTime).subtract(1, 'd').format('YYYY-MM-DD'),
      moment(endTime).add(1, 'd').format('YYYY-MM-DD'),
    );

    setEvents(_.concat(ggSchedules, geSchedules, myPaid));
  };

  // useEffect(() => {
  //   if (myPaid.length !== 0) {
  //     let newEvents = _.uniqBy(_.concat(events, myPaid), 'uuid');
  //     setEvents(newEvents);
  //   }
  // }, [myPaid]);

  return (
    <div style={{padding: '20px'}}>
      <Typography style={{textAlign: 'center', fontSize: '20px'}}
                  onClick={() => setOpen(!open)}
                  className={classes.text}
      > My Calendar</Typography>
      <div className={classes.calendar}>
        {open &&
        <FullCalendar
          ref={ref}
          plugins={[timeGridPlugin]}
          height={'auto'}
          contentHeight={'auto'}
          locale={locale}
          minTime={user.geniamUser.times.minTime.label || '00:00'}
          maxTime={user.geniamUser.times.maxTime.label || '24:00'}
          timeZone={'local'}
          titleFormat={{
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
          }}
          weekNumbers={false}
          defaultView={isMobileOnly ? 'CustomWeek' : 'custom2Week'}
          header={false}
          views={
            {
              custom2Week: {
                type: 'timeGrid',
                duration: {week: 2},
              },
              CustomWeek: {
                type: 'timeGrid',
                duration: {week: 1},
              },
            }
          }
          slotEventOverlap={false}
          columnHeaderFormat={{
            day: '2-digit',
            weekday: 'short',
            meridiem: false,
            omitCommas: false,
          }}
          progressiveEventRendering={true}
          nowIndicator={true}
          selectMirror={true}
          displayEventTime={true}
          allDayText={''}
          allDaySlot={true}
          eventTextColor={'white'}
          eventResizableFromStart={false}
          firstDay={1}
          events={events}
          eventRender={(info) => {
            if (info.event.extendedProps.source ||
              info.event.extendedProps.googleEvent)
              info.el.style.border = 'none';
            else
              info.el.style.background = '#ff4500';
            eventRender(info, classes);
          }}
          columnHeaderHtml={(info) => {
            return columnHeard(info, classes);
          }}
        />
        }
      </div>
    </div>
  );
}

export default CalendarComponent;
