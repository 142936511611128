import React, {useEffect} from 'react';
import './HomePage.css';
import Category from "../Category/Category";
import Content from "../Content/Content";
import CardCourse from "../Course/Course";
import DatePicker from "../DatePicker";
import Metamors from "../metamors/Metamors";
import LoadingFixFooter from "../LoadingFooter/LoadingFixFooter";
import {useGlobal} from 'reactn'
import {getSchedules} from "../actions/Schedules";
import {getRankPrices} from "../Cart/CartProductList";

function HomePage() {
  const [date] = useGlobal('date')
  useEffect(() => {
    getSchedules(date)
    getRankPrices()
  }, [date])
  return (
    <div className="wrapper">
      {/*<div className="text_top">Questum</div>*/}

      <div className="img_top">
        <img src="../img/cropped-BANNER-TOP-2.jpg" alt=""/>
      </div>
      <Category/>
      <Content/>
      {/*<CourseItem/>*/}
      <DatePicker/>
      <Metamors/>
      <CardCourse/>
      <LoadingFixFooter/>

    </div>
  )
}

export default HomePage;
