import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {Avatar} from '@material-ui/core'
import LoginRegisterButtons from "./LoginRegisterButton";
import {useGlobal, setGlobal} from 'reactn'
import AccountPopup from "./AccountPopup";
import {Link} from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import {ShoppingCart} from "@material-ui/icons";
import axios from "axios";
import {API_PREFIX} from "../../config/apiUrl";
import {isMobileOnly} from "react-device-detect";
export const getSubscriptions = async () => {
  try {
    const { data } = await axios.get(API_PREFIX +'/subscriptions?service_name=questum')
    // console.log(data)
    let subs = data.map(sub=>{
      return {...sub,schedule:sub.schedules[0].uuid}
    })
    setGlobal({
      subscriptions: subs
    })
    return subs
  } catch (e) {
    return Promise.reject(e)
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
     /*[theme.breakpoints.down('xs')]:{
           fontSize: '12pt',
       padding: '10px 0 5px 0',
       }*/
  },
  avatar: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  icon_cart_history :{
    display: "inline-block",
    verticalAlign: "middle"
  },
  icons: {
    fontSize: 30
  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();
  const [user] = useGlobal('user')
  const [myTickets] = useGlobal('myTickets')
  const [subscriptions] = useGlobal('subscriptions')
  // async function getNumSchedules (){
  //   const [data] = await map(schedule )
  // }
  function onClick() {
    window.location.href = '/history'
  }
  return (
    <div className={classes.root}>
      <AppBar position="fixed" style={{backgroundColor: '#3A3A3A'}}>
      {user.user_id ?

          <Toolbar style={{display: isMobileOnly ? "block" : 'flex'}}>
            <Typography variant="h6" className={classes.title} style={{textAlign: isMobileOnly ? "center" : 'left', fontSize: '12px', padding: '10px 0 5px 0'}}>
              <Link style={{color: "white", textDecoration: 'none'}} to="/">Beta Questum</Link>
            </Typography>
            <div style={{textAlign: 'right'}}>
              <div className={classes.icon_cart_history}>
                <IconButton color={"inherit"}
                            onClick={onClick}
                  // onClick={()=>{
                  //   this.props.history.push('/history')
                  // }}
                ><img style={{width: "30px", height: "30px"}} src="../img/icon-history-cart.png" alt=""/>
                </IconButton>
              </div>

              <IconButton color={"inherit"} onClick={() => {
                setGlobal({showCart: true})
              }}>
                <Badge color={"secondary"} badgeContent={subscriptions.length}>
                  <ShoppingCart className={classes.icons}/>
                </Badge>
              </IconButton>
              <h4 style={{
                display: "inline-block",
                verticalAlign: "middle",
                margin: "0 10px 0 0",
                lineHeight: "40px"
              }}>{myTickets} チケット</h4>
              <div style={{display: "inline-block", verticalAlign: "middle"}}>
                <Avatar className={classes.avatar} alt={'avatar'} src={user.avatar}
                        onClick={(e) => setGlobal({anchorEl: e.currentTarget})}/>
              </div>
            </div>
          </Toolbar>

      :
       <div>
        <Toolbar style={{display: "inline-block", width:'45%',boxSizing: 'border-box', verticalAlign: "middle"}}>
          <Typography variant="h6" className={classes.title} style={{ fontSize: '12pt', lineHeight: '56px'}}>
            <Link style={{color: "white", textDecoration: 'none'}} to="/">Beta Questum</Link>
          </Typography>
        </Toolbar>
        <div style={{textAlign: 'right', display: "inline-block", width: '55%', verticalAlign: "middle", boxSizing: 'border-box', paddingRight: '15px'}}>
          <LoginRegisterButtons/>
        </div>
       </div>

      }
      </AppBar>
      <AccountPopup/>
    </div>
  );
}

