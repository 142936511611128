import {Grid, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React, {useEffect, useState} from "react";
import axios from 'axios'
import {setGlobal, useGlobal} from 'reactn'
// import { isLogin } from "../../common/token";
import withStyles from "@material-ui/core/styles/withStyles";
import {getSubscriptions} from "./CartProductList";
import {API_PREFIX} from "../../config/apiUrl";
import {getSchedules} from "../actions/Schedules";

const styles = () => ({
  buyButton: {
    backgroundColor: 'green',
    color: 'white !important',
    '&:disabled': {
      backgroundColor: 'red'
    },
    '&:hover': {
      backgroundColor: 'green'
    },
  }
})

export default withStyles(styles)(MyTickets)

function MyTickets({classes}) {

  const [tickets, setTickets] = useState(0)
  const [loading, setLoading] = useState(false)
  const [totalOfTickets] = useGlobal('totalOfTickets')
  const [subscriptions] = useGlobal('subscriptions')
  const [user] = useGlobal('user')
  const [date] = useGlobal('date')
  useEffect(() => {
    getTickets()
  }, [])

  const getTickets = async () => {
    try {
      setLoading(true)
      const {data: {ticket}} = await axios.get(`${process.env.REACT_APP_GENIAM_API}/tickets?service_name=questum`)
      setTickets(ticket)
      setGlobal({
        myTickets: ticket
      })
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const onBuyClick = async () => {
    //未ログインの場合
    if (!user.user_id) {
      window.location.href = `${process.env.REACT_APP_MYACCOUNT_LOGIN}?redirect_url=${window.location.href}`
      return
    }
    // return
    // others
    try {
      setLoading(true)
      await axios.post(API_PREFIX + `/subscriptions/paid`, {
        subs: subscriptions.map(s => s.id),
        totalOfTickets: totalOfTickets,
        service_name: "questum"
      })
      getSchedules(date)
      getSubscriptions()
      getTickets()
      // setShowCart(false)
      setGlobal({showCart: false})
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }
  const isBuyButtonDisabled = () => {
    if (!subscriptions.length) return true
    if (!user.user_id) return false
    return loading || (tickets < totalOfTickets)
  }

  return (
    <Grid container
          direction={"column"}
      // alignContent={"stretch"}
          justify={"space-around"}
          alignItems={"center"}
          style={{height: '150px'}}>
      <Grid item>
        <Typography>保有チケット</Typography>
      </Grid>
      <Grid item>
        {user.user_id &&
        <Typography
          variant={"h5"}
        >{tickets}チケット</Typography>
        }
      </Grid>
      <Grid item>
        <Button
          className={classes.buyButton}
          disabled={isBuyButtonDisabled()}
          onClick={onBuyClick}
        >
          {
            user.user_id ? "チケットで予約" :
              "ログイン"
          }
        </Button>
      </Grid>
    </Grid>
  )
}
