import axios from 'axios'
import moment from 'moment'
import {API_PREFIX} from "../../config/apiUrl";
import {setGlobal, getGlobal} from 'reactn'
import _ from 'lodash'

export const getSchedules = async (date) => {
  let from_date = moment(date).startOf("week").format()
  let to_date = moment(from_date).add(14, "day").format()
  try {
    const {data} = await axios.get(API_PREFIX + `/questum/schedules?from_date=${from_date}&to_date=${to_date}`)
    if (data) {
      let myPaid = []
      data.schedules.forEach(sch => {
        if (sch.subscriptions && sch.subscriptions.length !== 0 && paidStatus(sch.subscriptions)) {
          myPaid.push(sch)
        }
      })
      setGlobal({schedules: _.orderBy(data.schedules, 'start', 'asc'), myPaid: myPaid})
    }
  } catch (e) {
    console.log(e)
  }
}
export const timeGetPick = (schedule) => {
  if (moment(schedule.start).utc() < moment().utc())
    return true
}

export const CheckPaid = (schedule) => {
  const {myPaid} = getGlobal()
  let idx = _.findIndex(myPaid, {'uuid': schedule.uuid})
  return idx !== -1
}
export const CheckMetamorRejected = (schedule) => {
  const {user} = getGlobal()
  let result = false
  if (!user.user_id) return false
  if (!schedule.subscriptions) return false
  schedule.subscriptions.forEach(sub => {
    if (sub.metamor_reject === 1 && sub.user === user.user_id)
      result = true
  })
  return result
}

const paidStatus = subs => {
  let result = false
  subs.forEach(sub => {
    if (sub.did_paid && !sub.user_reject && !sub.metamor_reject) {
      result = true
    }
  })
  return result
}
export const filterSchedules = () => {
  const {schedules, dateList, contents, metamor, categories} = getGlobal()
  let arr = []
  schedules.forEach(schedule => {
    if (checkDate(dateList, schedule) && contentCheck(contents, schedule) && metamorCheck(metamor, schedule) && categoriesCheck(categories, schedule))
      arr.push(schedule)
  })
  return arr
}
const categoriesCheck = (categories, schedule) => {
  let categoriesSelect = categories.map(c => {
    if (c.checked)
      return c
    return null
  })
  let arrCategories = _.intersectionBy(categoriesSelect, schedule.categories, 'id')
  return arrCategories.length !== 0
}

const checkDate = (dateList, schedule) => {
  // const dateNow = moment().local().format()
  let selectDate = dateList.map(date => {
    // if (date.date < dateNow)
    //   date.checked = false
    if (date.checked)
      return date
    return null
  })
  let startTime = moment(schedule.start).date()
  let index = _.findIndex(selectDate, {num: startTime})
  if (index !== -1) return true
  return false
}


const contentCheck = (contents, schedule) => {
  let contentSelect = contents.map(content => {
    if (content.checked)
      return content
    return null
  })
  let content = getSkill(schedule.skills)
  let index = _.findIndex(contentSelect, {'code': content})
  if (index !== -1) return true
  return false
}
const metamorCheck = (metamors, schedule) => {
  let metamorSelect = metamors.map(m => {
    if (m.checked)
      return m
    return null
  })
  let index = _.findIndex(metamorSelect, {'uuid': schedule.metamor_uuid})
  if (index !== -1) return true
  return false
}
export const getSkill = skills => {
  if (skills && skills.length !== 0) {
    let result = skills[0].code
    skills.forEach(skill => {
      if (skill.pivot.selected) {
        result = skill.code
      }
    })
    return result
  } else {
    return null
  }

}
