import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import currencyFormat from "../common/currencyFormat";
import { useGlobal } from "reactn";
import { withRouter } from 'react-router-dom'

function InputToBuyTickets(props) {

    const [numOfTickets, setNumOfTickets] = useState(1)
    const [myTickets] = useGlobal('myTickets')
    const [totalOfTickets] = useGlobal('totalOfTickets')

    useEffect(() => {
        const needBuyCoins = totalOfTickets - myTickets
        if (needBuyCoins > 0) {
          setNumOfTickets(needBuyCoins)
        } else {
          setNumOfTickets(0)
        }
    }, [myTickets, totalOfTickets])

    function goToCart() {
        const url = `${process.env.REACT_APP_CART_URL}/?add=GNM-001-0001-a2&service_name=questum&num=${numOfTickets}&taltailCoin=${totalOfTickets}&redirect_url=${window.location.href}`
        window.location.href = url
        localStorage.setItem('showCart', true)
    }

    return (
        <div>
            <Grid container direction={"column"} style={{ backgroundColor: "#F2F2F2", padding: 10 }}>
                <Grid item>
                    <Grid container alignItems={"center"} justify={"space-between"}>

                        <Grid item>
                            <Grid container justify={"center"} alignItems={"center"}>
                                <Grid item>
                                    <Typography variant={"h6"}>新規購入チケット数
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        style={{
                                            paddingLeft: 10,
                                            paddingRight: 10,
                                            width: 80
                                        }}
                                        id="outlined-number"
                                        value={numOfTickets}
                                        onChange={(e) => setNumOfTickets(e.target.value)}

                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{ min: "1" }}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography variant={"h6"}>チケット</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container justify={"center"}>
                                <Typography variant={"h5"}>
                                    {currencyFormat.format(+numOfTickets * 100)}（税別）
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    １チケット＝１００円（税別）
                </Grid>

                <Grid item style={{ paddingTop: 10 }}>
                    <Grid container justify={"space-between"}>
                        <Grid item style={{ backgroundColor: 'white' }}>
                            <Grid container direction={"column"}>
                                <Grid item>
                                    チケット一括購入特典
                                </Grid>
                                <Grid item>
                                    20コイン以上 <Present number={1}/>プレゼント
                                </Grid>
                                <Grid item>
                                    50コイン以上 <Present number={4}/>プレゼント
                                </Grid>
                                <Grid item>
                                    100コイン以上 <Present number={10}/>プレゼント
                                </Grid>
                                <Grid item>
                                    200コイン以上 <Present number={20}/>プレゼント
                                </Grid>
                                <Grid item>
                                    300コイン以上 <Present number={30}/>プレゼント
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container justify={"center"} direction={"column"} alignItems={"center"}
                                  alignContent={"center"}>
                                <Grid item>
                                    <Button variant={"outlined"}
                                            onClick={goToCart}
                                            style={{
                                                backgroundColor: 'orange',
                                                marginTop: 10
                                            }}>
                                        チケットを購入
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Typography variant={"caption"}
                                                style={{ paddingTop: 10 }}>
                                        ※購入したチケットは、換金返金はできません。
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>


        </div>
    )
}

function Present({ number }) {
    return (
        <span style={{ color: 'red' }}>{`${number}チケット`}</span>
    )
}

export default withRouter(InputToBuyTickets)
