import React from 'react'
import { Button } from "@material-ui/core";
import {LOGIN_URI, REGISTER_URI} from "../../config/apiUrl";


class LoginRegisterButtons extends React.Component {
    render() {
        return (

                <div>
                    <Button
                        style={{backgroundColor: '#8CC63F', color: '#fff'}}
                        onClick={()=>{
                            window.location = LOGIN_URI + '?redirect_url=' + window.location.href
                        }}
                    >Log in</Button>

                    <Button
                        style={{backgroundColor: '#8CC63F', color: '#fff', marginLeft: '10px'}}
                        onClick={()=>{
                            window.location = REGISTER_URI + '?redirect_url=' + window.location.href
                        }}
                    >
                        Register
                    </Button>
                </div>
        );
    }
}

export default LoginRegisterButtons

