import React, {useEffect} from 'react';
import {setGlobal, useGlobal} from 'reactn';
import './App.css';
import HomePage from './components/HomePage/HomePage';
import {Route, Switch, withRouter} from 'react-router-dom';
import MenuAppBar from './components/MenuAppBar/MenuAppBar';
import qs from 'query-string';
import {getUserData} from './components/actions/user';
import Cookies from 'js-cookie';
import {GENIAM_REFRESH_TOKEN} from './config/constant';
import {getSubscriptions} from './components/Cart/CartProductList';
import axios from 'axios';
import {OldBuyCourse} from './components/common/OldBuyCourse';
import MetamorItems from './components/metamors/MetamorItems';
import Cart from './components/Cart';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HistoryComponent from './components/HistorySubs';
import {ggTokenChecked} from './common/ggToken';
import _ from 'lodash';

require('./components/Ws/connectWs');

function App({history}) {
  // const [coins, setCoins] = useState(0)
  // const [loading, setLoading] = useState(false)
  const [user, setUser] = useGlobal('user');
  const [ggCalInit] = useGlobal('ggCalInit');
  const [googleCalendarInit] = useGlobal('googleCalendarInit');

  useEffect(() => {
    if (ggCalInit) {
      ggTokenChecked();
    }
  }, [user, ggCalInit]);

  useEffect(() => {
    if (googleCalendarInit) {
      window.gapi.client.calendar.calendarList.list().then(({result}) => {
        const data = result.items;
        _.remove(data, g => {
          return g.id.includes('#contacts@group') || g.accessRole ===
            'freeBusyReader' || g.id.includes('#holiday@group');
        });
        setGlobal({
          googleCalendars: data,
        }, () => {

        });
      }).catch(console.log);
    }
  }, [googleCalendarInit]);

  const getTickets = async () => {
    try {
      const {data: {ticket}} = await axios.get(
        `${process.env.REACT_APP_GENIAM_API}/tickets?service_name=questum`);
      // setCoins(coins)
      setGlobal({
        myTickets: ticket,
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const {
      token,
      refreshToken,
      showCart,
      donePaid,
    } = qs.parse(window.location.search);
    if (donePaid) {
      toast.success(' Thanks To Purchase ');
    }
    if (showCart) {
      setGlobal({showCart: true});
    }
    if (token || refreshToken) {
      const opts = {expires: 1826};
      if (process.env.NODE_ENV === 'production') opts.domain = '.geniam.com';
      localStorage.setItem('accessToken', token);
      Cookies.set(GENIAM_REFRESH_TOKEN, refreshToken, opts);
      localStorage.setItem('refreshToken', refreshToken);
      history.push('/');
    }
    getUserData().then(res => {
      OldBuyCourse();
      getSubscriptions();
      getTickets();
    }).catch(e => {
      console.log(e);
    });
    // eslint-disable-next-line
  }, []);

  // useFetchData()
  return (
    <div>
      <MenuAppBar/>
      <div className="App marTop">
        <Switch>
          <Route exact path={'/'} component={HomePage}/>
          <Route exact path={'/history'} component={HistoryComponent}/>
          <Route exact path={'/mtview'} component={MetamorItems}/>
          <Route component={NotFound}/>
        </Switch>
        <Cart/>
        <ToastContainer
          hideProgressBar={true}
          // closeOnClick={false}
          autoClose={3000}
        />
      </div>
    </div>
  );
}

function NotFound() {
  return (
    <div>
      Not Found
    </div>
  );
}

export default withRouter(App);
