import styled, {css} from "styled-components";
export const BorderButton = styled.div`
    width: 100%;
    height 10px;
    height: 10px; 
    left: 0;
    right: 0;
    bottom: -13px;
    border: none;
    outline: none !important;
    transition: all 0.1s ease-in-out;
    position:  absolute;
    
    ${props => props.checked && css`
        background: #F7931E;
  `}
    ${props => props.position && css`
        position:  inherit;
  `}
    height: ${props => props.date ? "5px" : "10px"};
    ${props => props.isMobile && css`
        height: 5px;
        bottom: -8px;
    `}
`;
