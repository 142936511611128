import React from 'reactn';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Popper from '@material-ui/core/Popper';
import _ from 'lodash'
import {isMobileOnly} from "react-device-detect";
import {Place, TrendingUp} from "@material-ui/icons";
import './MetamorItems.css'
const useStyles = makeStyles(theme => ({
  details: {
    display: 'block',
    width: "350px",
    background: "#ebebeb",
    padding: "15px",
    zIndex: "1",
    maxHeight: "50vh",
    overflowY: "auto",
    boxShadow: "0px 0px 3px #d0cfcf",
  },
  name:{
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: "10px;"
  },
  avatarMetamor: {
    width: "100px",
    height: "100px",
    border: "3px solid #b5b5d2",
    margin: "10px auto"
  },
  icon:{
    width: "20px",
    height: "20px",
    display: "inline-block",
    verticalAlign: "middle",
    marginRight: "10px"
  },
  text: {
    lineHeight: "21px",
    margin: "5px 0",
  },

}));

function MetamorItems(props) {
  const {metamor, metamorHoverAnchor, setMetamorHoverAnchor} = props
  const classes = useStyles();
  // console.log(metamorHoverAnchor)
  function handleClose() {
    setMetamorHoverAnchor(null);
  }
  const open = Boolean(metamorHoverAnchor);
  const id = open ? 'simple-popover' : undefined;

  if (_.isEmpty(metamor)) return null
  return (
    <Popper
      children
      id={id}
      open={open}
      anchorEl={metamorHoverAnchor}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {/*<Typography className={classes.typography}>The content of the Popover.</Typography>*/}

        <Card className={classes.card} isMobile={isMobileOnly}>
          <div className={classes.details}>
            <Typography style={{fontSize: 20, fontWeight: "bold", textAlign: "center"}}>
              詳細な教師情報:</Typography>
            <Avatar alt="avatar metamors" src={(metamor.profile && metamor.profile.avatar) || "img/hi.jpg"}
                    className={classes.avatarMetamor}/>
            <div className={classes.name}>
              {metamor.name}
            </div>
            <div style={{textAlign: "center"}}>{metamor.profile.slug}</div>
            <div className={classes.text}>
              <Place className={classes.icon}/> {metamor.profile.tz}
            </div>

            <div className={classes.text}>
              <TrendingUp className={classes.icon}/>{metamor.profile.rank}
            </div>
            <div className={classes.text}>
              {metamor.profile.description}
            </div>
          </div>
        </Card>
    </Popper>
  );
}

export default MetamorItems;
