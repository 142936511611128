import React, {useEffect} from 'react';
import styled, {css} from 'styled-components';
import './Content.css';
import {BorderButton} from "../common/BorderButton";
import {useGlobal} from 'reactn'
import {getContents} from "../actions/Contents";
import {isMobileOnly} from "react-device-detect";


const ContentButton = styled.button`
    display: inline-block;
    position: relative;
    border: solid 1px #000;
    font-size: 28pt;
    outline: none !important;
    width: 8%;
    margin: 0 0.5%;
    cursor: pointer;
    padding: 0;
    ${props => props.isMobile && css`
        width: 12%;
    `}
`;
const ImgContent = styled.div`
    position: relative;
    padding-top: 100%;
`;

function Content() {
    const [contents, setContents] = useGlobal('contents')
    useEffect(() => {
        getContents()
    }, [])

    const toggleContent = (index) => {
        if (contents[index]) {
            contents[index] = {...contents[index], checked: !contents[index].checked}
            setContents([...contents])
        }
    }
    return (
        <div>

          <h3 style={{
            fontZize: "26pt",
            margin: 0,
            lineHeight: "50px",
          }}>コンテンツ</h3>
            {contents.map((content, index) => {
                return (
                    <ContentButton isMobile ={isMobileOnly} key={index} onClick={() => toggleContent(index)}>
                        <ImgContent>
                            <img className="img" src={`image/${content.code}.png`} alt=""/>
                        </ImgContent>
                        <BorderButton isMobile ={isMobileOnly} checked={content.checked}/>
                    </ContentButton>
                )
            })}

        </div>
    )
}

export default Content;
