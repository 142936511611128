import React from 'react'
import CartDialog from "./CartDialog";
import DelPopup from "./DelPopup";

export default function Cart() {

    return (
        <div>
            <CartDialog/>
            <DelPopup />
        </div>
    )
}
