import React from 'react';
import {Button, Card, CardActions, CardContent, Popover, Typography} from "@material-ui/core";
import {useGlobal} from 'reactn'
import Cookies from 'js-cookie'
import {ACCESS_TOKEN_KEY, GENIAM_REFRESH_TOKEN} from "../../config/constant";
import axios from 'axios'
import {API_GENIAM} from "../../config/apiUrl";

function AccountPopup(props) {
  const [anchorEl, setAnchorEL] = useGlobal('anchorEl')
  const [user] = useGlobal('user')

  const myAccount = () => {
    window.location.assign(`${process.env.REACT_APP_MYACCOUNT}/account`)
  }
  const onSwitchAccount = () => {
    window.location.assign(`${process.env.REACT_APP_MYACCOUNT}/login/select-account?redirect_url=` + window.location.href)
  }
  const onClickLogout = async () => {
    try {
      await axios.post(API_GENIAM + '/logout')
    } catch (e) {

    }
    const isProd = process.env.REACT_APP_ENV === 'production'
    const isStg = process.env.REACT_APP_ENV === 'stg'
    const domain = (isProd || isStg) ? '.geniam.com' : null
    localStorage.clear()
    localStorage.removeItem(ACCESS_TOKEN_KEY)
    Cookies.remove(GENIAM_REFRESH_TOKEN, {domain})
    window.location.reload()


    return (null)
  }
  return (
    <div className="AccountPopup">
      <Popover
        id={"AccountPopup"}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEL(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <Card>
          <CardContent style={{display: 'flex', justifyContent: 'space-around'}}>
            <div>
              <Typography variant="title" gutterBottom>
                {`${user.last_name || ''} ${user.first_name || ''}`}
              </Typography>
            </div>
            <div>
              <Button
                className="btnPrimary noUnderline"
                onClick={myAccount}>
                アカウント
              </Button>
            </div>
          </CardContent>
          <CardActions style={{display: 'flex', justifyContent: 'center'}}>
            <Button variant={"outlined"} className={"noUnderline"}
                    onClick={onSwitchAccount}>アカウントの切り替え</Button>
            <Button variant={"outlined"}
                    onClick={onClickLogout}
            >ログアウト</Button>
          </CardActions>

        </Card>

      </Popover>

    </div>
  );
}

export default (AccountPopup)
