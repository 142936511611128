import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { useGlobal } from "reactn";
import CartProductList from "./CartProductList";
import { Grid } from "@material-ui/core";
import MyCoins from "./MyTickets";
import InputToBuyTickets from "./InputToBuyTickets";

function CartDialog() {

    const [showCart, setShowCart] = useGlobal('showCart')

    function handleClose() {
        setShowCart(false)
    }

    return (
        <div>
            <Dialog
                id={"CartDialog"}
                open={showCart}
                onClose={handleClose}
                transitionDuration={0}
                aria-labelledby="responsive-dialog-title"
            >

                <DialogContent>
                    <Button variant={"contained"}
                            style={{ backgroundColor: 'green', color: 'white' }}
                            onClick={handleClose}
                    >セッションをさらに予約</Button>
                    <div style={{ height: 20 }}/>

                    <Grid container justify={"space-around"} style={{ backgroundColor: "#F2F2F2", padding: 10 }}>
                        <Grid item>
                            <CartProductList/>
                        </Grid>
                        <Grid item>
                            <MyCoins/>
                        </Grid>
                    </Grid>
                    <div style={{ height: 20 }}/>
                    <InputToBuyTickets/>
                </DialogContent>
                <DialogActions>
                    {/*<Button onClick={handleClose} color="primary">*/}
                    {/*    Disagree*/}
                    {/*</Button>*/}
                    {/*<Button onClick={handleClose} color="primary" autoFocus>*/}
                    {/*    Agree*/}
                    {/*</Button>*/}
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default (CartDialog);
