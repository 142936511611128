import {addCallback, setGlobal} from 'reactn';
import moment from 'moment';

setGlobal({
  calIds: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(i => 'Cal_' + i),
  numOfCals: 1,
  date: moment().format(),
  contents: [],
  schedules: [],
  dateList: [],
  showCart: false,
  subscriptions: [],
  metamor: [],
  tickets: 0,
  categories: [],
  user: {},
  myTickets: 0,
  anchorEl: null,
  loading: false,
  rankPrices: [],
  myPaid: [],
  history: [],
  hover: false,
  metamorHoverAnchor: null,
  hoverMetamor: {},
  totalOfTickets: 0,
})
addCallback(globalState => {
  console.log(globalState)
})
