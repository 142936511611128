import React, {useEffect, useState} from 'react';
import moment from 'moment';
import 'moment/locale/ja';
import {useGlobal} from 'reactn';
import {Grid, IconButton, withStyles} from '@material-ui/core';
import {KeyboardArrowLeft, KeyboardArrowRight} from '@material-ui/icons';
import {isMobileOnly} from 'react-device-detect';
import {BorderButton} from '../common/BorderButton';
import CalendarComponent from '../Calendar/Calendar';

const style = theme => ({
  divBtn: {
    '&:hover': {
      cursor: 'pointer',
    },
    /* [theme.breakpoints.down('xs')]:{
         color: '#ff0000'
     }*/
  },
})

function DatePicker(props) {
  const [date, setDate] = useGlobal('date')
  const [user] = useGlobal('user')
  const [dateList, setDateList] = useGlobal('dateList')
  const [range] = useState(isMobileOnly ? 7 : 14)
  useEffect(() => {
    moment.locale('ja')
    let startDate = moment(date).startOf("week").format()
    let today = moment().format('YYYY-MM-DD')
    let endDate = moment(date).startOf("week").add(range, "day").format()
    let arr = []
    for (let i = 0; i < range; i++) {
      let dateNow = moment(startDate).add(i, "day").format('YYYY-MM-DD')
      let month = moment(dateNow).month() + 1
      let color = today === dateNow ? '#4CE157' : (moment(dateNow).weekday() === 0 ? '#DB000F' : 'black')
      arr.push({
        month: month,
        date: dateNow,
        dayOfWeek: moment(dateNow).weekday(),
        num: moment(dateNow).date(),
        text: moment(dateNow).format('dd'),
        checked: moment(today).isBetween(startDate, endDate, null, '[]') ? !OverTime(dateNow) : true,
        color
      })
    }
    setDateList(arr)
  }, [date])
  const OverTime = date => {
    if (moment(date) < moment().startOf("day"))
      return true
    return false
  }
  const onPrev = () => {
    setDate(moment(date).subtract(range, "day").format())
  }
  const onNext = () => {
    setDate(moment(date).add(range, "day").format())
  }
  const toggleDate = index => {
    if (dateList[index]) {
      dateList[index] = {...dateList[index], checked: !dateList[index].checked}
      setDateList([...dateList])
    }
  }
  // const timeGetDate = dateList => {
  //   let dateNow = moment().startOf('day').format()
  //   let dateShow = moment(dateList.date).format()
  //   if (dateShow < dateNow)
  //     return true
  //   else return false
  // }
  const {classes} = props
  return (
    <div>
      <Grid container style={{width: '100%', marginTop: 30}}>
        <Grid item xs={1} style={{textAlign: 'center'}}>
          <IconButton style={{padding: isMobileOnly ? `12px 0` : `12px`}} onClick={onPrev}>
            <KeyboardArrowLeft/>
          </IconButton>
        </Grid>
        <Grid item xs={10}>
          <div style={{width: '100%', display: 'flex', marginLeft: isMobileOnly ? `7px` : `${100 / range}px`}}>
            {dateList.map((d, i) => {
              // if (timeGetDate(d)) return (
              // <div key={i} style={{width: `${100 / range}%`, marginLeft: '-1px', boxSizing: "border-box"}}
              //      className={classes.divBtn}>
              //   <div
              //     style={{border: '1px solid #3A3A3A', textAlign: 'center'}}
              //   >
              //     <span style={{color: `${d.color}`}}>{d.month}/{d.num}</span>
              //     <br/>
              //     <span style={{color: `${d.color}`}}>({d.text})</span>
              //   </div>
              // </div>
              // )
              return (
                <div key={i} style={{
                  width: `${100 / range}%`, marginLeft: '-1px', boxSizing: "border-box",
                  opacity: OverTime(d.date) ? 0.4 : 1
                }}
                     onClick={() => toggleDate(i)}
                     className={classes.divBtn}>
                  <div
                    style={{border: '1px solid #3A3A3A', textAlign: 'center'}}
                  >
                    <span style={{color: `${d.color}`}}>{d.month}/{d.num}</span>
                    <br/>
                    <span style={{color: `${d.color}`}}>({d.text})</span>
                  </div>
                  <BorderButton checked={d.checked} position={true} date={true}/>
                </div>
              )
            })}
          </div>
        </Grid>
        <Grid item xs={1} style={{textAlign: 'center'}}>
          <IconButton style={{padding: isMobileOnly ? `12px 0` : `12px`}} onClick={onNext}>
            <KeyboardArrowRight/>
          </IconButton>
        </Grid>
      </Grid>
      {user.user_id &&
      <div>
        <CalendarComponent/>
      </div>
      }

    </div>
  );
}

export default withStyles(style)(DatePicker)
