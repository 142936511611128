import React, {useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import {
  CheckMetamorRejected,
  CheckPaid,
  getSkill,
  timeGetPick
} from "../actions/Schedules";
import moment from 'moment'
import Button from "@material-ui/core/Button";
import axios from "axios";
import {API_PREFIX, LOGIN_URI} from "../../config/apiUrl";
import {useGlobal} from "reactn"
import _ from "lodash";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import PersonIcon from "@material-ui/icons/Person";
import LinearScale from "@material-ui/icons/LinearScale";
import {TrendingUp} from "@material-ui/icons";
import Tooltip from '@material-ui/core/Tooltip';
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "blue",
    opacity: 100,
  },
  card: {
    maxWidth: "100%",
    width: 560,
    color: "black",
    fontWeight: "bold",
    height:"auto",
    overflow:"auto",
    '&::-webkit-scrollbar': {
      width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#adaaaa',
        borderRadius: '5px',
      },
   /* '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 4px #ebebeb',
      borderRadius: '10px',
    },*/

  },
media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    // backgroundColor: red[500],
  },
  imgFirst: {
    position: 'relative',
    paddingTop: '60%',
    margin: '5px',
  }
  ,
  img: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    objectFit: 'cover',
  },
  icon:{
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: '10px',
    color: '#000',
  },
  buyButton: {
    '&:hover': {
      backgroundColor: '#4e963a !important',
    },
  },
  text:{
    marginBottom: '10px',
    fontSize: '1rem',
    textAlign:'justify',
    color: '#000',
  },
  customDialog:{
    backgroundColor:'red'
  }

}));


function CourseItems(props) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const {date, schedule} = props
  const [subscriptions, setSubscriptions] = useGlobal('subscriptions')
  let content = getSkill(schedule.skills)
  const [user] = useGlobal('user')
  // const [schedules] = useGlobal('schedules')
  // const [data, setData] = useState([])
  // const [courseItemDetails, openCourseItemDetails] = useState(false)
  // useEffect(() => {
  //     setData(filterSchedules())
  // }, [schedules])

  // function handleExpandClick() {
  //   setExpanded(!expanded);
  // }

  function handleDialog() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }
  const isCheck = (schedule) => {
    let idx = _.findIndex(subscriptions, {schedule: schedule.uuid})
    return idx !== -1
  }
  const AddSubscriptions = async () => {
    try {
      const titless = schedule.title
      const minutess = (moment(schedule.end) - moment(schedule.start)) / (1000 * 60)
      const ranks = schedule.metamor.profile.rank
      const starts = schedule.start
      const {data} = await axios.post(API_PREFIX + '/subscriptions/add', {
        "content": titless,
        "minutes": minutess,
        "rank": ranks,
        "start": starts,
        schedule: schedule.uuid,
        "service_name": "questum"

      })
      subscriptions.push({...data.newSubs, schedule: schedule.uuid})
      setSubscriptions([...subscriptions])
      setOpen(!open)
    } catch (e) {

    }
  }
  // const isCheck = (schedule) => {
  //   let idx = _.findIndex(subscriptions, {schedule: schedule.uuid})
  //   return idx !== -1
  // }
  function onClick() {
    if (user.user_id) {
      AddSubscriptions(schedule.uuid)
      setOpen(!open)
    } else {
      window.location.href = LOGIN_URI + '?redirect_url=' + window.location.href
    }
  }
  return (
    <div className={classes.imgFirst}>
      <img className={classes.img} src={schedule.img || '/image/contentimage.png'} alt={''}
           onClick={handleDialog}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Card className={classes.card}>
          <CardHeader
            avatar={
              <Avatar src={`/image/${content}.png`} aria-label="Recipe" className={classes.avatar}>
              </Avatar>
            }
            action={
              <IconButton aria-label="exit" onClick={handleClose}>
                <img alt={""} src={'/img/outline-close-24px.svg'} />
              </IconButton>
            }
            title={<h3 style={{fontSize: '18px', margin: '0'}}>{schedule.service_name}</h3>}
            subheader={schedule.title}
          />
          <CardMedia
            style={{margin: '0 15px'}}
            className={classes.media}
            image={schedule.img}
            title="Content Course Geniam"
          />
          <CardContent>
              <h3 style={{color:'#79b369', margin: '0 0 10px 0'}}>{schedule.content}</h3>
            <Typography className={classes.text} variant="body2" color="textSecondary"
                        component="p">
              <Tooltip title="Time">
                <TimelapseIcon className={classes.icon}/>
              </Tooltip>
              {moment(schedule.start).format('HH:mm')} ~ {moment(schedule.end).format('HH:mm')} {moment(date.date).format('YYYY-MM-DD')}
            </Typography>
            <Typography className={classes.text} variant="body2" color="textSecondary"
                        component="p">
              <Tooltip title="Metamor">
              <PersonIcon className={classes.icon}/>
              </Tooltip>{schedule.metamor.name}
            </Typography>


            <Typography className={classes.text}>
              <Tooltip title="Coma">
              <LinearScale className={classes.icon}/>
              </Tooltip>{schedule.coma}
            </Typography>
            <Typography className={classes.text}>
              <Tooltip title="Rank">
                <TrendingUp className={classes.icon}/>
              </Tooltip>{schedule.metamor.profile.rank}
            </Typography>
            <Typography className={classes.text}>
              {schedule.descriptions}
            </Typography>
          </CardContent>
          <CardActions disableSpacing style={{textAlign:'center', display:'block', paddingTop: '0'}}>
            { !CheckPaid(schedule) && !isCheck(schedule) && !timeGetPick(schedule) && !CheckMetamorRejected(schedule) ?
              <Button style={{backgroundColor: "#79b369", color:'#fff'}} className={classes.buyButton} onClick={onClick}>
                コースを購入する </Button>
              :
              null
            }
          </CardActions>
        </Card>
      </Dialog>
    </div>
  );


}

export default CourseItems;
