import React, {useState, useEffect} from 'react';
import axios from 'axios'
import moment from 'moment'
import './style.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import HistoryIcon from "@material-ui/icons/History";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import PersonIcon from "@material-ui/icons/Person";
import EventIcon from "@material-ui/icons/Event";
import {API_PREFIX} from "../../config/apiUrl";
import { useGlobal} from 'reactn'
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import _ from 'lodash'
import DialogActions from "@material-ui/core/DialogActions";
import {getRankPrices} from "../Cart/CartProductList";

function ticketReturn(time, ticket) {
  let startDay = moment(time).local().format('YYYY-MM-DD')
  let nowDay = moment().format('YYYY-MM-DD')
  if (startDay === nowDay) {
    if (moment(time).local().hour() - moment().hour() > 1) {
      return parseInt(ticket * 50 / 100)
    } else {
      return 0
    }
  } else {
    if ((moment(time).date() - moment().date()) > 1) {
      return ticket
    } else {
      if (moment().hour() >= 17) {
        return parseInt(ticket * 80 / 100)
      } else {
        return ticket
      }
    }
  }
}

function Come({come, setReject}) {
  const [rankPrices] = useGlobal('rankPrices')
  const [ticket, setTicket] = useState(0)
  useEffect(() => {
    let idx = _.findIndex(rankPrices, {'rank': come.rank})
    if (idx !== -1) {
      let ticket = (rankPrices[idx].price_before_tax * come.coma) / 100
      setTicket(ticket)
    }
  }, [rankPrices])
  const handleReturn = () => {
    let returnTicket = ticketReturn(come.start, ticket)
    setReject({
      uuid: come.id,
      ticket: returnTicket,
    })
  }
  if (!come || rankPrices.length === 0) return null
  return (
    <div className="product">
      <div className="title_pro">{come.contents} </div>
      <div className="updated_at">
        <HistoryIcon/>{moment(come.updated_at).local().format('HH:mm YYYY-MM-DD')}
      </div>
      <div><PersonIcon/>{come.schedules[0].metamor.name}</div>
      <div><EventIcon/>{come.coma}</div>
      <div className="time">
        <TimelapseIcon/>{moment(come.start).local().format('HH:mm')} ~ {moment(come.end).local().format('HH:mm YYYY-MM-DD')}
      </div>
      <div
        className="price">{ticket} 枚
      </div>
      {(moment(come.start).local() > moment().local()) && !come.user_reject && !come.metamor_reject ?
        <Button variant={'contained'} color={'secondary'}
                onClick={handleReturn}
        >
          Cancel Schedule
        </Button>
        : ''
      }
      {(come.user_reject || come.metamor_reject) ?
        <Typography style={{fontWeight: "bold"}} color={"secondary"}>
          Schedule Cancelled
        </Typography>
        : ''
      }
    </div>

  )
}

function DialogReject({coming, setComing, reject, setReject}) {
  const [loading, setLoading] = useState(false)
  const [myTickets, setMyTickets] = useGlobal('myTickets')

  const handleReject = async () => {
    if (!loading) {
      setLoading(true)
      try {
        let {data} = await axios.post('/subscriptions/user-reject', {
          sub_id: reject.uuid,
          ticket: reject.ticket,
          service_name: 'questum'
        })
        if (data.doneReject && data.okCart) {
          let next = [...coming]
          _.remove(next, c => c.id === reject.uuid)
          setComing(next)
          setMyTickets(myTickets + reject.ticket)
          setReject(null)
          setLoading(false)
        } else {
          setReject(null)
          setLoading(false)
        }

      } catch
        (e) {
        console.log(e)
        setLoading(false)
        setReject(null)
      }
    }
  }

  if (!reject) return null
  return (
    <Dialog open={Boolean(reject.uuid)}
            onClose={() => setReject(null)}
    >
      <DialogContent>
        Do you want reject this schedules ?
        (will return {reject.ticket} ticket)
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setReject(null)} disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleReject} disabled={loading}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function ComingUp() {
  const [comming, setComming] = useState([])
  const [page, setPage] = useState(1)
  const [perPage] = useState(10)
  const [total, setTotal] = useState(0)
  const [lastPage, setLastPage] = useState(1)
  const [reject, setReject] = useState(null)
  useEffect(() => {
    axios.get(`${API_PREFIX}/subscriptions/comingup?service_name=questum&page=${page}&perPage=${perPage}`)
      .then(res => {
        setComming([...comming, ...res.data.data])
        setLastPage(res.data.lastPage)
        setTotal(res.data.total)
      })
      .catch(e => {
        console.log(e)
      })
  }, [page])
  useEffect(() => {
    getRankPrices()
  }, [])
  const loadMore = () => {
    setPage(page + 1)
  }
  return (
    <div className="wrapper_history" style={{marginTop: 20}}>
      <h3 className="title">Coming Up</h3>
      {total === 0 ? "" : ''}
      <InfiniteScroll
        dataLength={comming.length} //This is important field to render the next data
        next={loadMore}
        hasMore={lastPage > page}
        loader={<h4 style={{zIndex: 9999, color: 'red'}}>Loading...</h4>}
        /* endMessage={ <p style={{textAlign: 'center'}}>
             <b>het me roi</b>
         </p>}*/
        // below props only if you need pull down functionality
      >
        {comming.length !== 0 && comming.map((c, index) => {
          return <Come come={c} key={index} setReject={setReject}/>
        })}
      </InfiniteScroll>
      <DialogReject
        coming={comming}
        setComing={setComming}
        reject={reject}
        setReject={setReject}
      />
    </div>
  );
}

export default ComingUp;
