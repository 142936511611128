import { Popover } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import React, { useState } from "react";
// import { useKeyboardJs } from "react-use";
import axios from 'axios'
import { setGlobal, useGlobal } from 'reactn'

function DelPopup({ anchorEl, sub, onClose }) {

    // const [enterPressed] = useKeyboardJs('enter');
    const [loading] = useState(false)
    const [subs] = useGlobal('subscriptions')
    const open = Boolean(anchorEl)
    // useEffect(() => {
    //     if (anchorEl && enterPressed) onDelete()
    // }, [enterPressed])

    const onDelete = async () => {
        try {
            await axios.delete('/subscriptions/del', {
                params: {
                    sub: sub.id
                }
            })

            const subidx = subs.findIndex(s => s.id === sub.id)
            subs.splice(subidx, 1)

            setGlobal({ subscriptions: subs })
            if (onClose) onClose(false)
        } catch (e) {
            window.location.assign(`${process.env.REACT_APP_MYACCOUNT}/login?redirect_url=${window.location.href}`)

        } finally {


        }

    }
    const onNoDelete = () => {
        if (onClose) onClose(false)
    }

    return (
        <Popover
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
            onEscapeKeyDown={onNoDelete}
            onBackdropClick={onNoDelete}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <Typography variant={"h6"}>削除しますか？</Typography>
            <Grid container={true} justify={"space-around"}>
                <div>
                    <Button
                        disabled={loading}
                        color={"secondary"} onClick={onDelete}>削除</Button>
                </div>
                <div>
                    <Button
                        disabled={loading}
                        onClick={onNoDelete}>戻る</Button>
                </div>
            </Grid>
        </Popover>
    )
}

export default DelPopup
