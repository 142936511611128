import moment from 'moment';

export function columnHeard(info, classes) {
  const isToday = moment(info).format('YYYY-MM-DD') ===
    moment().format('YYYY-MM-DD');
  const isSat = moment(info).isoWeekday() === 6;
  const isSun = moment(info).isoWeekday() === 7;
  const style = isToday ?
    `style="background-color: black; color:white;font-size: 2.5em"`
    :
    isSun ?
      `style="color:#ff867f;font-size: 2.5em"`
      :
      isSat ?
        `style="color:#1B97F0;font-size: 2.5em"`
        :
        `style="font-size: 2.5em"`;

  const style1 = isSun ?
    `style="color:#ff867f"`
    :
    isSat ?
      `style="color:#1B97F0"`
      :
      '';

  return `<div style="position: relative; border: "none"}">
                               <div class="${classes.headerHtmlDay} ${classes.headerHtmlSmallDay}}" ${style1}>${moment(
    info).format('ddd')}</div>
                               <div class="${classes.headerHr}"></div>
                               <div class="${classes.headerHtml} ${classes.headerHtmlSmall}" ${style} >
                                    ${info.getDate()}
                               </div>
                            </div>`;
}
