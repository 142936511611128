import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';

export function eventRender(info, classes) {

  const element = <div className={classes.events} id={info.event.id}
                       style={{position: 'relative', zIndex: 9999}}>
    <div style={{fontSize: '10px'}}>
      {moment(info.event.start).format('HH:mm')} - {moment(info.event.end).
      format('HH:mm')}
    </div>
    <div style={{fontSize: '10px'}}>
      <span>{info.event.title}</span>
    </div>
  </div>;
  ReactDOM.render(element, info.el.firstChild);
}
