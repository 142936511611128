import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Card} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useGlobal} from 'reactn'
import moment from 'moment'
import {
  CheckMetamorRejected,
  CheckPaid,
  filterSchedules,
  getSkill,
  timeGetPick
} from "../actions/Schedules";
import _ from 'lodash'
import Checked from "../Checked/Checked";
import axios from "axios";
import {API_PREFIX} from "../../config/apiUrl";
import CourseItems from "./CourseItems";
import TimelapseIcon from "@material-ui/icons/Timelapse";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    paddingTop: 30,
    margin: "auto",
    paddingBottom: 85,
  },
  imgFirst: {
    position: 'relative',
    paddingTop: '60%',
    margin: '5px',
  }
  ,
  img: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    objectFit: 'cover',
  },
    Courseitem:{
      position: 'relative',
      boxShadow: 'none',
      border: 'solid 1px #bdbdbd',
    },
  contentimg: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    objectFit: 'cover',
  },
  ct_img: {
    position: 'relative',
    paddingTop: '100%',
  },
  Grid: {
    width: "auto",
    height: "auto",
    paddingTop: 30
  },
  GridItem: {
    width: 400,
    height: "auto",
  },
  bottom: {
    padding: 10,
    display: "block",
  },
  item: {
    marginRight: 15,
  },
  coin: {
    color: "white",
    display: 'block',
    lineHeight: '35px',
    textAlign: 'center'
  },
  divCoin: {
    width: 55,
    height: 35,
    backgroundColor: "black"
  },
  paided: {
    position: 'absolute',
    top: '37%',
    left: '50%',
    color: 'red',
    border: 'solid 2px',
    padding: '5px 16px',
    transform: 'rotate(-45deg)',
    marginLeft: '-56px',
    pointerEvents:'none',
  },
  rejected:{
    position: 'absolute',
    top: '37%',
    left: '50%',
    color: 'red',
    border: 'solid 2px',
    padding: '5px 16px',
    transform: 'rotate(-45deg)',
    marginLeft: '-93px',
    pointerEvents:'none',
  },
  icon:{
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: '7px',
    color: '#000',
  },
  title:{
    color:'#79b369',
    fontWeight:'500',
    margin:'0',
    lineHeight:'24px',
    display: 'inline-block',
    verticalAlign: 'middle',
    maxHeight: '48px',
    overflow: 'hidden',
  }
}))

function CardCourse() {
  const classes = useStyles()
  const [schedules] = useGlobal('schedules')
  const [dateList] = useGlobal('dateList')
  const [contents] = useGlobal('contents')
  const [metamors] = useGlobal('metamor')
  const [categories] = useGlobal('categories')
  const [user] = useGlobal('user')
  const [rankPrices] = useGlobal('rankPrices')
  const [loading, setLoading] = useGlobal('loading')
  const [subscriptions, setSubscriptions] = useGlobal('subscriptions')
  const [data, setData] = useState([])
  useEffect(() => {
    setData(filterSchedules())
  }, [contents, schedules, dateList, metamors, categories, user]);


  const AddSubscriptions = async (schedule) => {
    setLoading(true)
    try {
      let content = getSkill(schedule.skills)
      const minutess = schedule.coma * 60
      const ranks = schedule.metamor.profile.rank
      const starts = schedule.start
      const {data} = await axios.post(API_PREFIX + '/subscriptions/add', {
        "contents": content,
        "minutes": minutess,
        "rank": ranks,
        "start": starts,
        "service_name": 'questum',
        schedule: schedule.uuid,
      })
      subscriptions.push({...data.newSubs, schedule: schedule.uuid})
      setSubscriptions([...subscriptions])
    } catch (e) {

    }
    setLoading(false)
  }

  const DelSubscription = async (schedule) => {
    setLoading(true)
    try {
      let index = _.findIndex(subscriptions, {schedule: schedule.uuid})
      if (index !== -1) {
         await axios.delete(API_PREFIX + '/subscriptions/del?sub=' + subscriptions[index].id)
        _.remove(subscriptions, sub => sub.id === subscriptions[index].id)
        setSubscriptions([...subscriptions])
      }
    } catch (e) {

    }
    setLoading(false)
  }

  const subscription = (schedule) => event => {
    if (user.user_id) {
      console.log(schedule)
      let idx = _.findIndex(subscriptions, {schedule: schedule.uuid})
      if (idx === -1) {
        AddSubscriptions(schedule)
      } else {
        DelSubscription(schedule)
      }

    } else
      alert('Bạn cần đăng nhập')
    /* _.remove(subscriptions, sub =>sub.uuid === schedule.uuid)
 setSubscriptions([...subscriptions])*/
  }
  const isCheck = (schedule) => {
    let idx = _.findIndex(subscriptions, {schedule: schedule.uuid})
    return idx !== -1
  }
  return (
    <div className={classes.root}>
      {dateList.map((date, i) => {
        if (!date.checked) return null
        return (
          <Grid key={i} className={classes.Grid} container spacing={2}>
            <div style={{width: '100%', paddingLeft: 30}}>
              <Typography style={{fontWeight: "bold", fontSize: 22}}>
                {`${moment(date.date).format('M/D')}`} 火曜日
              </Typography>
            </div>
            {data && data.length !== 0 && data.map(schedule => {
              let content = getSkill(schedule.skills)
              let price = 0
              let idx = _.findIndex(rankPrices, {'rank': schedule.metamor.profile.rank})
              if (idx !== -1) {
                price = rankPrices[idx].price_before_tax * schedule.coma / 100
              }

              let schedule_date = moment(schedule.start).local().format('YYYY-MM-DD')
              if (date.date !== schedule_date) return null
              return (
                <Grid key={schedule.uuid} className={classes.GridItem} item
                      xs={12} sm={6} md={4}>
                  <Card className={classes.Courseitem}>
                    <CourseItems date={date} onChange={subscription}
                                 schedule={schedule}/>
                    <div style={{padding:'0 10px'}}>
                      <div style={{margin:'0 0 5px 0',lineHeight: '48px'}}>
                        <h3 className={classes.title}>{schedule.content || "お金 の葛藤をひも解く"}</h3>
                      </div>
                      <Typography style={{opacity: '0.8',fontStyle: 'italic'}}><TimelapseIcon className={classes.icon}/>{moment(schedule.start).format('HH:mm')}~ {moment(schedule.end).format('HH:mm')}</Typography>
                    </div>
                    <div className={classes.bottom}>
                      <Grid container spacing={0} style={{width: '100%'}}>
                        <Grid item xs={2} className={classes.item}>
                          <div className={classes.ct_img}>
                            <img className={classes.contentimg}
                                 src={`/image/${content}.png`}
                                 alt={''}/>
                          </div>

                        </Grid>
                        <Grid item xs={4} className={classes.item}>
                          <div className={classes.contentname}>
                            <Typography
                              style={{marginTop: 10}}>{content}</Typography>
                          </div>
                        </Grid>
                        <Grid item xs={2} className={classes.item}>
                          <div className={classes.divCoin}>
                            <span className={classes.coin}>{price}</span>
                          </div>
                        </Grid>
                        <Grid item xs={1} className={classes.item}>
                          {!CheckPaid(schedule) && !timeGetPick(schedule) && !CheckMetamorRejected(schedule) &&
                          <div className={classes.checkbox}>
                            <Checked
                              onChange={subscription}
                              schedule={schedule}
                              disabled={loading}
                              checked={isCheck(schedule)}
                            />
                          </div>
                          }
                        </Grid>
                      </Grid>


                    </div>
                    {
                      CheckPaid(schedule) ?
                        <div className={classes.paided}>
                          <Typography
                            style={{fontSize: '24px'}}
                          ><strong>購入済み</strong></Typography>
                        </div>
                        :
                        CheckMetamorRejected(schedule) ?
                          <div className={classes.rejected}>
                            <Typography
                              style={{fontSize: '14px'}}
                            ><strong>キャンセル済み</strong></Typography>
                          </div>
                          :
                          ''
                    }
                  </Card>
                </Grid>
              )
            })}

          </Grid>
        )
      })}

    </div>

  )
}

export default CardCourse
