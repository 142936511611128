import Ws from '@adonisjs/websocket-client'
import {getGlobal, setGlobal} from 'reactn'
import _ from 'lodash'
import {getSchedules} from "../actions/Schedules";
import axios from "axios";

const wsUrl = process.env.REACT_APP_WS_QUESTUM
const ws = Ws(wsUrl)
ws.connect()

export let schedule
export let subscriptions
ws.on('open', () => {
  schedule = ws.subscribe('METAMOR_CALENDAR')
  subscriptions = ws.subscribe('SUBSCRIPTIONS')

  //schedule actiosns
  schedule.on('updated', (data) => {
    let sendData = typeof data === 'object' ? data : JSON.parse(data)
    if (data.service_name === 'questum') {
      const {schedules, date} = getGlobal()
      let index = _.findIndex(schedules, {uuid: sendData.uuid})
      if (index !== -1) {
        if (schedules[index].rrule !== sendData.rrule) {
          getSchedules(date)
        } else {
          schedules[index] = sendData
          setGlobal({schedules: [...schedules]})
        }

      }
    }
  })
  schedule.on('added', (data) => {
    const {schedules} = getGlobal()
    let sendData = typeof data === 'object' ? data : JSON.parse(data)
    if (data.service_name === 'questum' && sendData.skills && sendData.skills.length !== 0) {
      schedules.push(sendData)
      setGlobal({schedules: _.uniqBy(schedules, 'uuid')})
    }
  })
  schedule.on('deletedSchedule', (data) => {
    let sendData = typeof data === 'object' ? data : JSON.parse(data)
    const {schedules} = getGlobal()
    if (sendData.all) {
      _.remove(schedules, s => s.uuid === data.uuid || s.recurring_event_id === data.uuid)
      setGlobal({schedules: [...schedules]})
      // getSchedules(date)
    } else {
      if (sendData.repeat) {
        // getSchedules(date)
        _.remove(schedules, s => s.recurring_event_id === data.uuid)
        setGlobal({schedules: [...schedules]})
      } else {
        _.remove(schedules, s => s.uuid === data.uuid)
        setGlobal({schedules: [...schedules]})
      }
    }
  })

  subscriptions.on('deleted', (data) => {
    const {subscriptions} = getGlobal()
    let idx = _.findIndex(subscriptions, {'id': data})
    if (idx !== -1) {
      _.remove(subscriptions, subscriptions[idx])
      setGlobal({subscriptions: [...subscriptions]})
    }
  })

  // subscriptions actions
  subscriptions.on('cancel', sub => {
    const {date, user} = getGlobal()
    if (sub.service_name === 'questum' && user.user_id && user.user_id === sub.user && sub.metamor_reject) {
      getTickets()
      getSchedules(date)
    }
  })
})

const getTickets = async () => {
  try {
    const {data: {ticket}} = await axios.get(`${process.env.REACT_APP_GENIAM_API}/tickets?service_name=questum`)
    setGlobal({
      myTickets: ticket
    })
  } catch (e) {
    console.log(e)
  } finally {
  }
}
