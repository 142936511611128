import React from 'react';
import './Checked.css';
import {useGlobal} from 'reactn'
import {LOGIN_URI} from "../../config/apiUrl";

function Checked(props) {
    const {onChange, schedule, checked} = props
    const [user] = useGlobal('user')

    function onClick() {
        localStorage.setItem('oldBuyCourse', JSON.stringify(schedule))
        window.location = LOGIN_URI + '?redirect_url=' + window.location.href
    }

    return (
        <div className="input">
            <label>
                <i/>
                {user.user_id ?
                    <input type="checkbox" name="" value="" onChange={onChange(schedule)} checked={checked}/>
                    :
                    <input type="checkbox" name="" value="" onChange={onClick}/>
                }
                <img src="../img/Asset1.png" alt={''}/>
            </label>
        </div>
    )
}

export default Checked;
